<template>
  <div class="container-fluid contain" style="margin-bottom: 300px;">
    <div class="title" style="margin-left: 0">Delivery Cart</div>
    <div class="empty-cart" v-if="!get(state, 'currentCart.id')">Your cart is empty, please add items to your cart.
    </div>
    <div class="row mt-2" v-if="get(state, 'currentCart.id')">
      <div class="col-lg-9 col-mg-12 mt-2">
        <div v-if="items.length > 0">
          <div v-for="(item, index) in items" :key="index">
						<span class="deliver-text" v-if="item.isPickUp">
						Drive Up on {{get(item, 'deliverBy')}} between 1PM-8PM
					</span>
            <span class="deliver-text" v-else-if="item.isBrunch">
						{{get(item, 'deliverBy')}} between 1PM-8PM
					</span>
            <span class="deliver-text" v-else>
						Deliver on {{get(item, 'deliverBy')}} between 11AM - 1PM
					</span>
            <div v-if="item.isPickUp" class="deliver-address">
              Picking up from Private Location
            </div>
            <div v-else-if="item.isBrunch" class="deliver-address">
              Private Location
            </div>
            <div class="d-flex flex-row" v-else>
              <div class="deliver-address">Delivering to</div>
              <a class="deliver-address" style="text-decoration-line: underline; color: #c30000; margin-left: 5px;"
                 @click="onClickAddressMore">{{get(item, 'deliverTo.name')}}</a>
            </div>
            <div class="item-cart row mr-0 ml-0" v-for="(item, index) in item.data" :key="index">
              <div class="item-body col-sm-12 col-md-8">
                <img :src="get(item, 'product.images[0].url', '')"
                     style="width: 30%; height: 100px; object-fit: contain; align-self: flex-start">
                <div style="margin-left: 30px">
                  <span class="item-title" style="padding: 0;">{{item.product.name}}</span>
                  <div class="deliver-text" style="font-weight: 500; color: #A0A0A0; font-size: 13px;">
                    {{getIngredients(item)}}
                  </div>
                </div>
              </div>
              <div class="price-view col-sm-12 col-md-4">
                <div>
                  <div class="count-view">
                    <button class="btn count-btn" @click="onClickCount(item.id, false)"><span>-</span></button>
                    <span class="count-item">{{item.quantity}}</span>
                    <button class="btn count-btn" @click="onClickCount(item.id, true)">+</button>
                  </div>
                  <div style="text-align: center; color: grey; font-size: 13px; margin-top: 5px;">
                    ${{item.product.pricing[0].retailPrice}} each
                  </div>
                </div>

                <div style="margin-left: 20px; width: 50px; text-align: right">
                  <span style="font-weight: bold; font-size: 22px;">${{item.product.pricing[0].retailPrice * item.quantity}}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 col-md-12">
            <a class="row justify-content-between align-items-center" style="margin-top: 30px;" @click="onSelectTip(0)"
               v-if="!hidden">
              <div class="row justify-content-center align-items-center ml-3">
                <div style="width: 21px; height: 21px; border-radius: 11px; background-color: #dbdbdb"
                     v-if="tipIndex !== 0"></div>
                <div style="width: 21px; height: 21px; border-radius: 11px; background-color: black" v-else>
                  <img src="@/assets/images/icon_feather_check.png" style="margin-left: 3px; margin-bottom: 2px;">
                </div>
                <div style="font-weight: 600; font-size: 12px; line-height: 15px; margin-left: 10px;">10% Tip</div>
              </div>
              <div style="font-weight: 600; font-size: 12px; line-height: 15px; width: 56px; text-align: center">
                ${{(cart.subtotal / 100 * 10).toFixed(2)}}
              </div>
            </a>
            <a class="row justify-content-between align-items-center" style="margin-top: 17px;" @click="onSelectTip(1)"
               v-if="!hidden">
              <div class="row justify-content-center align-items-center ml-3">
                <div style="width: 21px; height: 21px; border-radius: 11px; background-color: #dbdbdb"
                     v-if="tipIndex !== 1"></div>
                <div style="width: 21px; height: 21px; border-radius: 11px; background-color: black" v-else>
                  <img src="@/assets/images/icon_feather_check.png" style="margin-left: 3px; margin-bottom: 2px;">
                </div>
                <div
                  style="font-weight: 600; font-size: 12px; line-height: 15px; margin-left: 10px; display: flex; flex-direction: row">
                  15% Tip
                  <span style="font-weight: normal; margin-left: 3px;">(Recommended)</span>
                </div>
              </div>
              <div style="font-weight: 600; font-size: 12px; line-height: 15px; width: 56px; text-align: center">
                ${{(cart.subtotal / 100 * 15).toFixed(2)}}
              </div>
            </a>
            <a class="row justify-content-between align-items-center" style="margin-top: 17px;" @click="onSelectTip(2)"
               v-if="!hidden">
              <div class="row justify-content-center align-items-center ml-3">
                <div style="width: 21px; height: 21px; border-radius: 11px; background-color: #dbdbdb"
                     v-if="tipIndex !== 2"></div>
                <div style="width: 21px; height: 21px; border-radius: 11px; background-color: black" v-else>
                  <img src="@/assets/images/icon_feather_check.png" style="margin-left: 3px; margin-bottom: 2px;">
                </div>
                <div style="font-weight: 600; font-size: 12px; line-height: 15px; margin-left: 10px;">20% Tip</div>
              </div>
              <div style="font-weight: 600; font-size: 12px; line-height: 15px; width: 56px; text-align: center">
                ${{(cart.subtotal / 100 * 20).toFixed(2)}}
              </div>
            </a>
            <a class="row justify-content-between align-items-center" style="margin-top: 10px;" @click="onSelectTip(3)"
               v-if="!hidden">
              <div class="row justify-content-center align-items-center ml-3">
                <div style="width: 21px; height: 21px; border-radius: 11px; background-color: #dbdbdb"
                     v-if="tipIndex !== 3"></div>
                <div style="width: 21px; height: 21px; border-radius: 11px; background-color: black" v-else>
                  <img src="@/assets/images/icon_feather_check.png" style="margin-left: 3px; margin-bottom: 2px;">
                </div>
                <div style="font-weight: 600; font-size: 12px; line-height: 15px; margin-left: 10px;">Custom Tip</div>
              </div>
              <input class="tip-input" placeholder="$--" style="padding: 0 10px;" v-model="customTip">
              <!--								$-->
              <!--								<input placeholder="$&#45;&#45;" style="height: 10px; width: 18px; background-color: transparent; font-weight: 600;font-size: 12px;line-height: 15px;color: #6B6B6B;"/>-->

            </a>
          </div>
          <div class="col-lg-3 col-md-12"></div>
          <div class="col-lg-4 col-md-12">
            <div class="desc-view" style="margin-top: 18px;">
              <span class="desc-text text-right">Subtotal</span>
              <span class="desc-text text-right" style="width: 70px;">{{get(cart, 'subtotal', 0).toFixed(2)}}</span>
            </div>
            <div class="desc-view">
              <span class="desc-text text-right">Tax</span>
              <span class="desc-text text-right" style="width: 70px;">{{get(cart, 'tax', 0).toFixed(2)}}</span>
            </div>
            <div class="desc-view" v-if="!hidden">
              <span class="desc-text text-right">Tip</span>
              <span class="desc-text text-right" style="width: 70px;">{{get(cart, 'tip', 0).toFixed(2)}}</span>
            </div>
            <!--						<div class="desc-view">-->
            <!--							<span class="desc-text text-right">Service Charge</span>-->
            <!--							<span class="desc-text text-right" style="width: 70px;">{{get(cart, 'serviceFee', 0).toFixed(2)}}</span>-->
            <!--						</div>-->
            <!--						<div class="desc-view">-->
            <!--							<span class="desc-text text-right">Flute Wallet</span>-->
            <!--							<span class="desc-text text-right" style="width: 70px;">{{get(cart, 'wallet', 0).toFixed(2)}}</span>-->
            <!--						</div>-->
            <!--						<div class="desc-view">-->
            <!--							<span class="desc-text text-right">Total Due Now</span>-->
            <!--							<span class="desc-text text-right" style="width: 70px;">{{get(cart, 'dueNow', 0).toFixed(2)}}</span>-->
            <!--						</div>-->
            <!--						<div class="desc-view">-->
            <!--							<span class="desc-text text-right" style="color: #00bf00">Instant Rebates (Wallet)</span>-->
            <!--							<span class="desc-text text-right" style="width: 70px;color: #00bf00">{{get(cart, 'rebates', 0).toFixed(2)}}</span>-->
            <!--						</div>-->
            <div class="desc-view">
              <span class="desc-text text-right font-weight-bold">Grand Total</span>
              <span class="desc-text text-right font-weight-bold" style="width: 70px;">
						${{get(cart, 'total', 0).toFixed(2)}}
					</span>
            </div>
          </div>
        </div>

      </div>
      <div class="col-lg-3 col-mg-12 address-margin">
        <div class="address-view">
          <div class="address-header">DELIVERY ADDRESS</div>
          <a class="address-content mt-2" v-for="(address, index) in addresses" :key="index"
             @click="setAddressIndex(index)">
            <div style="width: 20px;">
              <img src="@/assets/images/icon_check.png" style="width: 17px" v-if="selectedAddressIndex === address.id">
            </div>
            <div style="margin-left: 10px; width: 70%">
              <div class="address-text" :style="selectedAddressIndex === address.id ? 'opacity: 1': 'opacity: 0.4'">
                {{address.name}}
              </div>
              <div class="address-text font-weight-light"
                   :style="selectedAddressIndex === address.id ? 'opacity: 1': 'opacity: 0.4'">
                {{`${address.address} ${address.address2 ? `Unit # ${address.address2},` : ''}
                ${address.city}, ${address.state} ${address.postalCode}`}}
              </div>
              <div class="address-text font-weight-light"
                   :style="selectedAddressIndex === address.id ? 'opacity: 1': 'opacity: 0.4'">
                {{getPhoneNumber(get(address, 'phones[0].number', ''))}}
              </div>
            </div>
            <button class="btn edit" v-if="selectedAddressIndex === address.id" @click.stop.prevent="onEditAddress">
              EDIT
            </button>
          </a>
          <div style="justify-content: center; display: flex">
            <button class="btn more" v-if="get(state, 'currentUser.sites.length', 0) > 3" @click="onClickAddressMore">
              <div class="more-circle"/>
              <div class="more-circle"/>
              <div class="more-circle"/>
            </button>
          </div>
          <div style="justify-content: center; display: flex">
            <button class="btn address-add" @click="onClickAddress">ADD SHIPPING ADDRESS</button>
          </div>
        </div>
        <!--<div class="payment-method-view">-->
          <!--<div class="address-header">PAYMENT METHOD</div>-->
          <!--<a class="address-content mt-2"-->
             <!--v-for="(payment, index) in payments && payments.length > 0 ? [...payments] : []" :key="index"-->
             <!--@click="setPaymentIndex(index)">-->
            <!--<div style="width: 20px;">-->
              <!--<img src="@/assets/images/icon_check.png" style="width: 17px" v-if="selectedPaymentIndex === payment.id">-->
            <!--</div>-->
            <!--<img src="@/assets/images/icon_apple.png" style="width: 30px; margin-left: 10px;" v-if="payment.id === 1">-->
            <!--<img :src="payment.image" style="width: 30px; margin-left: 10px;" v-else>-->
            <!--<div class="address-text ml-1" :style="selectedPaymentIndex === payment.id ? 'opacity: 1': 'opacity: 0.4'">-->
              <!--{{payment.id === 1 ? 'Pay with Apple Pay' : `Card ending in ${payment.last4}`}}-->
            <!--</div>-->
            <!--&lt;!&ndash;                        <button class="btn edit"&ndash;&gt;-->
            <!--&lt;!&ndash;                                v-if="selectedPaymentIndex === payment.id && selectedPaymentIndex !== 1">&ndash;&gt;-->
            <!--&lt;!&ndash;                            EDIT&ndash;&gt;-->
            <!--&lt;!&ndash;                        </button>&ndash;&gt;-->
          <!--</a>-->
          <!--<div style="justify-content: center; display: flex">-->
            <!--<button class="btn more" v-if="get(state, 'currentUser.paymentMethods.length', 0) > 3"-->
                    <!--@click="onClickPaymentMore">-->
              <!--<div class="more-circle"/>-->
              <!--<div class="more-circle"/>-->
              <!--<div class="more-circle"/>-->
            <!--</button>-->
          <!--</div>-->
          <!--<div style="justify-content: center; display: flex">-->
            <!--<button class="btn address-add" data-target="#addPaymentModal" data-toggle="modal">ADD PAYMENT METHOD-->
            <!--</button>-->
          <!--</div>-->
        <!--</div>-->
        <v-braintree
          :authorization="get(state, 'token', '')"
          @success="onSuccess"
          @error="onError"
          class="mt-3"
          v-if="state.token"
        ></v-braintree>
        <div class="delivery-notes-view">DELIVERY NOTES</div>
        <textarea
          style="border: 1px solid #d6d6d6; box-sizing: border-box; border-radius: 5px; width: 100%; font-size: 14px; padding: 5px;"
          rows="6" v-model="notes"></textarea>
        <div class="text-center deliver-description">
          By confirming this order, you agree to sharing your delivery information to our 3rd party
          courier.
        </div>
        <button class="btn confirm d-flex justify-content-center align-items-center" @click="onClickConfirm">
          <div class="loader" v-if="state.isLoading"></div>
          <div v-else>CONFIRM DELIVERY</div>
          <div
            style="position: absolute; right: 25px; font-size: 10px; line-height: 30px; color: white; letter-spacing: 1px;"
            v-if="!state.isLoading">${{get(cart, 'total', 0).toFixed(2)}}
          </div>
        </button>
      </div>
    </div>
    <DeliverTo :updateAddress="updateAddress" :selectedAddress="selectedAddress"/>
    <Addresses
      :selected-index="selectedAddressIndex"
      :update-select-address="updateSelectAddress"
      :edit-address="onEditAddressByModal"/>
    <Payments :selected-index="selectedPaymentIndex" :update-select-payment="updateSelectPayment"/>
    <AddPayment :update-payment="updatePayment"/>
  </div>
</template>

<script>
  import {reactive, toRefs} from "@vue/composition-api";
  import {json} from 'overmind';
  import {get, groupBy} from 'lodash';
  import moment from 'moment';
  import $ from 'jquery';
  import DeliverTo from "./modals/DeliverTo";
  import Addresses from "./modals/Addresses";
  import Payments from "./modals/Payments";
  import AddPayment from "../pages/modals/AddPayment";
  import {Settings} from '../../settings';

  export default {
    components: {
      DeliverTo,
      Addresses,
      Payments,
      AddPayment
    },
    setup(props, {root}) {
      const getData = () => {
        const newData = [];
        let items = json(root.state.currentCart?.items);
        if (items?.length > 0) {
          items.map(i => i.formatDeliverBy = moment(i.deliverBy).format('dddd, MMMM D, YYYY'));
          let data = groupBy(items, 'formatDeliverBy');
          data = Object.values(data);
          data = data.sort((a, b) => a[0]?.deliverBy > b[0]?.deliverBy ? 1 : -1);
          data.map(i => newData.push({
              data: i,
              date: i[0].deliverBy,
              deliverBy: moment(i[0]?.deliverBy, null).format('dddd, MMMM D, YYYY'),
              deliverTo: i[0].deliverTo,
              isPickUp: i.filter(p => p.notes?.includes('Drive Up'))?.length > 0,
              isBrunch: i.filter(p => p.notes?.includes('Brunch') || p.notes?.includes('After hour'))?.length > 0
            })
          );
          return newData;
        }
      };
      const data = reactive({
        cart: json(root?.state.currentCart),
        items: getData() || [],
        hidden: json(root?.state.currentCart)?.items?.filter(i => i.notes?.includes('Drive Up') || i.notes?.includes('Brunch'))?.length > 0,
        addresses: json(get(root, 'state.currentUser.sites', [])?.slice(0, 3)),
        payments: [],
        selectedPaymentIndex: null,
        oriAddresses: json(root?.state.currentUser.sites),
        selectedAddressIndex: json(root?.state.currentUser?.sites.slice(0, 3)).find(a => a.id === get(root, 'state.currentCart.items[0].deliverTo.id', ''))?.id || json(root.state.currentUser.sites.slice(0, 3)[0]?.id),
        moment,
        get,
        selectedAddress: null,
        tipIndex: 1,
        customTip: null,
        notes: null,
        isModal: false,
      });
      const onSuccess = async (payload) => {
        $('.btn-primary').text('');
        $('.btn-primary').addClass('d-flex justify-content-center align-items-center');
        $('.btn-primary').append('<div class="loader" v-if="state.isLoading"></div>');
        try {
          const param = {
            type: 'payment',
            cardType: payload?.details?.cardType,
            last4: payload?.details?.lastFour,
            token: payload?.nonce,
            isValid: true,
            isDefault: true
          };
          await root.actions.user.onAddPayment(param);
        } catch (e) {
          console.log(e);
        } finally {
          $('.btn-primary').text('Add New Card');
          $('.loader').remove()
        }
      };

      const onError = (error) => {
        console.log(error)
      };
      const getBraintreeToken = async () => {
        const loader = root.$loading.show();
        try {
          if (!root.state.currentUser?.id) {
            const { token, userId } = await root.actions.getStoredAuthToken();
            await root.actions.loginWithTokenForBraintree({ token, userId });
          } else if (root.state.currentUser?.id && !root.state.token) {
            await root.actions.user.onCreateBraintreeToken();
          }
        } catch (e) {
          console.log(e)
        } finally {
          loader.hide();
        }
      };


      const getPhoneNumber = (number) => {
        if (!number) {
          return '';
        } else {
          return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, 10)}`
        }
      };
      const getPaymentMethods = () => {
        const validPayments = root.state.currentUser.paymentMethods.filter(p => p.type === 'payment' && p.isValid);
        const defaultPayment = validPayments?.find(p => p.type === 'payment' && p.isValid && p.isDefault);
        if (defaultPayment) {
          data.payments = [...json(validPayments.filter(p => p.id !== defaultPayment.id)?.slice(0, 2)), ...[json(defaultPayment)]];
        } else {
          data.payments = json(validPayments?.slice(0, 3));
        }
        data.selectedPaymentIndex = defaultPayment ? defaultPayment?.id : data.payments[0] ? data.payments[0]?.id : 1;
      };

      const setPaymentIndex = (index) => {
        data.selectedPaymentIndex = index === 3 ? 1 : data.payments[index].id;
      };

      const setAddressIndex = async (index) => {
        const loader = root.$loading.show();
        try {
          const updateItems = [];
          root.state.currentCart?.items?.map(p => updateItems.push({
            quantity: p?.quantity,
            productId: p?.product?.id,
            deliverBy: p?.deliverBy,
            deliverTo: {id: data.addresses[index].id},
            id: p?.id
          }));
          const params = {updateItems, cartId: root.state.currentCart?.id};
          await root.actions.cart.saveCart(params);
          const addresses = json(root.state.currentUser?.sites);
          data.items = getData();
          const deliverToAddressIndex = addresses?.findIndex(s => s.id === get(root, 'state.currentCart.items[0].deliverTo.id', ''));
          data.selectedAddressIndex = addresses[deliverToAddressIndex]?.id;
        } catch (e) {
          console.log(e);
        } finally {
          loader.hide();
        }
      };

      const onPressRemove = async (id) => {
        const loader = root.$loading.show();
        try {
          if (root.state.currentCart?.items.length === 1) {
            await root.actions.cart.emptyCart({deleteCart: true, cartId: data.cart?.id});
            root.$router.go(-1);
          } else {
            const item = root.state.currentCart?.items.find(d => d.id === id);
            const removeItems = [{
              quantity: 0,
              id: item.id,
              productId: item?.product?.id,
              deliverBy: new Date(item.deliverBy || new Date()),
              deliverTo: {id: item.product?.id}
            }];
            const params = {cartId: data.cart?.id, removeItems};
            const cart = await root.actions.cart.saveCart(params);
            data.cart = cart;
            data.items = getData();
          }
        } catch (e) {
          console.log(e);
        } finally {
          loader.hide();
        }
      };

      const onClickCount = async (id, isPlus) => {
        console.log(id, isPlus);
        const items = [...root.state.currentCart.items];
        if (isPlus) {
          const loader = root.$loading.show();
          try {
            const item = items.find(i => i.id === id);
            const updateItems = [{
              quantity: item?.quantity + 1,
              productId: item?.product?.id,
              deliverBy: item?.deliverBy,
              deliverTo: {id: item?.deliverTo?.id},
              id: item?.id
            }];
            const params = {updateItems, cartId: data.cart.id};
            const saveCart = await root.actions.cart.saveCart(params);
            data.items = getData();
            data.cart = saveCart;
            data.isOpen = false;
          } catch (e) {
            console.log(e);
          } finally {
            loader.hide();
          }
        } else if (!isPlus && items.find(i => i.id === id).quantity > 0) {
          if (items.find(i => i.id === id).quantity === 1) {
            root.$confirm({
              title: 'Remove Cart',
              message: 'Do you wish to remove this item from your cart?',
              button: {
                yes: 'Ok',
                no: 'Cancel'
              },
              callback: async (confirm) => {
                if (confirm) {
                  await onPressRemove(id)
                } else {
                  return false;
                }
              }
            });
          } else {
            const loader = root.$loading.show();
            try {
              const item = items.find(i => i.id === id);
              const updateItems = [{
                quantity: item?.quantity - 1,
                productId: item?.product?.id,
                deliverBy: item?.deliverBy,
                deliverTo: {id: item?.deliverTo?.id},
                id: item?.id
              }];
              const params = {updateItems, cartId: data.cart.id};
              const saveCart = await root.actions.cart.saveCart(params);
              data.items = getData();
              data.cart = saveCart;
            } catch (e) {
              console.log(e);
            } finally {
              loader.hide();
            }
          }
        }
      };

      // const cartItemDeliverTo = (site) => {
      //   let params = {};
      //   if (!site) {
      //     return {}
      //   }
      //   if (site.id) {
      //     params = {deliverTo: {id: site.id}}
      //   } else if (site.details && site.details.place_id) {
      //     params = {googlePlacesId: site.details.place_id}
      //   } else if (site.details && site.details.coords) {
      //     const {latitude, longitude} = site.details.coords;
      //     params = {gps: {lat: latitude, longitude}}
      //   }
      //
      //   if (site.address2) params.address2 = site.address2;
      //   return params;
      // };

      const createOrder = async () => {
        if ((root.state.currentUser?.paymentMethods || [])?.filter(p => p.type === 'payment' && p.isValid)?.length === 0) {
          if (data.isModal) {
            $('#addPaymentModal').modal('show');
            return;
          } else {
            root.$router.push('/addPayment');
            return;
          }
        }
        await root.actions.startLoading();
        try {
          // if (data.notes) {
          //     const params = {cartId: data.cart.id, deliveryNotes: data.notes};
          //     await root.actions.cart.saveCart(params);
          // }
          const orderParams = {
            cartId: data.cart.id,
            tipType: '$',
            tipAmount: data.cart.tip,
            paymentMethodId: data.selectedPaymentIndex
          };
          if (data.notes) orderParams.notes = data.notes;
          if (data.selectedPaymentIndex === 1) delete orderParams.paymentMethodId;
          const order = await root.actions.order.createOrder(orderParams);
          root.$router.push({
            path: 'cart-success',
            query: root.$route.query.event === 'hush' ? {id: order[0].id, event: 'hush'} : {id: order[0].id}
          });
        } catch (e) {
          console.log(e)
        } finally {
          await root.actions.finishLoading();
        }
      };

      const onClickApple = () => {

      };

      const onClickConfirm = async () => {
        if (data.selectedPaymentIndex === 1) {
          await onClickApple();
          await createOrder();
        } else {
          if (data.payments.length === 0) {
            onClickPayment();
            return;
          }
          await createOrder()
        }
      };

      const onClickAddress = () => {
        if (data.isModal) {
          data.selectedAddress = null;
          $('#deliverModal').modal('show');
        } else {
          root.$router.push('/address')
        }
      };

      const onClickPayment = () => {
        if (data.isModal) {
          $('#addPaymentModal').modal('show');
        } else {
          root.$router.push('/addPayment')
        }
      };

      const updateAddress = () => {
        data.addresses = json(root.state.currentUser.sites.slice(0, 3));
      };

      const updatePayment = (user) => {
        const methods = user?.paymentMethods.filter(p => p.type === 'payment' && p.isValid);
        data.payments[0] = json(methods[methods.length - 1]);
        data.selectedPaymentIndex = methods[methods.length - 1]?.id
      };

      const onEditAddress = () => {
        data.selectedAddress = data.addresses.find(a => a.id === data.selectedAddressIndex);
        if (data.isModal) {
          $('#deliverModal').modal('show');
        } else {
          root.$router.push({path: '/address', query: {id: data.selectedAddress.id}})
        }
      };

      const onClickAddressMore = () => {
        if (data.isModal) {
          $('#addressesModal').modal('show');
        } else {
          root.$router.push({path: '/addresses', query: {addressId: data.selectedAddressIndex}})
        }
      };

      // const onClickPaymentMore = () => {
      //   $('#paymentsModal').modal('show');
      // };

      const getAddresses = () => {
        const addresses = json(root.state.currentUser?.sites);
        const deliverToAddressIndex = addresses?.findIndex(s => s.id === get(root, 'state.currentCart.items[0].deliverTo.id', ''));
        data.selectedAddressIndex = addresses[deliverToAddressIndex]?.id;
        addresses?.splice(deliverToAddressIndex, 1);
        data.addresses = [json(root.state.currentUser?.sites[deliverToAddressIndex]), ...addresses.slice(0, 2)]
      };
      const updateSelectAddress = async (id) => {
        try {
          const updateItems = [];
          root.state.currentCart?.items?.map(p => updateItems.push({
            quantity: p?.quantity,
            productId: p?.product?.id,
            deliverBy: p?.deliverBy,
            deliverTo: {id: root.state.currentUser?.sites?.find(s => s.id === id).id},
            id: p?.id
          }))
          const params = {updateItems, cartId: root.state.currentCart?.id};
          await root.actions.cart.saveCart(params);
          getAddresses();
          data.items = getData();
          $('#addressesModal').modal('hide');
        } catch (e) {
          console.log(e);
        } finally {
          await root.actions.finishLoading();
        }
      };

      const updateSelectPayment = () => {
        getPaymentMethods();
      };

      const onEditAddressByModal = (id) => {
        data.selectedAddress = json(root.state.currentUser?.sites)?.find(a => a.id === id);
        if (data.isModal) {
          $('#deliverModal').modal('show');
        } else {
          root.$router.push({path: '/address', query: {id: id}})
        }

      };
      const onSelectTip = (index) => {
        data.tipIndex = index;
        data.customTip = null;
        if (index === 0) {
          data.customTip = null;
          data.cart.total = data.cart?.subtotal + data.cart?.tax + data.cart?.subtotal / 100 * 10;
          data.cart.tip = data.cart?.subtotal / 100 * 10;
        } else if (index === 1) {
          data.customTip = null;
          data.cart.total = data.cart?.subtotal + data.cart?.tax + data.cart?.subtotal / 100 * 15;
          data.cart.tip = data.cart?.subtotal / 100 * 15;
        } else if (index === 2) {
          data.customTip = null;
          data.cart.total = data.cart?.subtotal + data.cart?.tax + data.cart?.subtotal / 100 * 20;
          data.cart.tip = data.cart?.subtotal / 100 * 20;
        }
      };

      const getIngredients = (item) => {
        const ingredients = [];
        if (item.product?.tasks?.length > 0) {
          item.product.tasks.map(o => {
            if (o.inventoryProduct && o.inventoryProduct.ingredient && item.modifiers.findIndex(m => m.id === o.id) > -1)
              ingredients.push('No ' + o?.inventoryProduct?.ingredient?.name);
          })
        }
        if (ingredients.length > 0) {
          return ingredients.join(', ');
        } else {
          return 'With Everything'
        }
      };
      return {
        getPaymentMethods,
        setPaymentIndex,
        setAddressIndex,
        onClickCount,
        onClickConfirm,
        onClickAddress,
        updateAddress,
        updatePayment,
        onEditAddress,
        onClickPayment,
        onClickAddressMore,
        // onClickPaymentMore,
        updateSelectAddress,
        updateSelectPayment,
        onEditAddressByModal,
        onSelectTip,
        getPhoneNumber,
        getAddresses,
        getIngredients,
        getData,
        getBraintreeToken,
        onSuccess,
        onError,
        ...toRefs(data)
      }
    },
    beforeCreate() {
      if (!this.state.currentUser?.id) {
        this.$router.push({path: '/', query: this.isEvent ? {event: 'hush'} : {}})
      }
    },
    computed: {
      isEvent() {
        return this.$route.query.event === 'hush'
      }
    },
    mounted() {
      typeof this.getPaymentMethods === 'function' && this.getPaymentMethods();
      typeof this.getAddresses === 'function' && this.getAddresses();
      $('.btn-primary').text('Add New Card');
      console.log(Settings.flow)
      this.isModal = Settings.flow === 'modal';
    },
    watch: {
      customTip: function (val, oriVal) {
        if (!isNaN(val)) {
          this.cart.total = this.cart?.subtotal + this.cart?.tax + parseFloat(val);
          this.cart.tip = parseFloat(val);
        } else {
          this.customTip = oriVal;
          this.cart.total = this.cart?.subtotal + this.cart?.tax + parseFloat(oriVal);
          this.cart.tip = parseFloat(oriVal);
        }
      }
    }
  }
</script>

<style lang="css" scoped>
  .tip-input {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #6B6B6B;
    width: 56px;
    height: 33px;
    border-radius: 20px;
    background-color: #e4e4e4;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .empty-cart {
    text-align: center;
  }

  .item-cart {
    display: flex;
    flex-direction: row;
    padding: 22px 26px;
    justify-content: space-between;
    background-color: #fbfbfb;
    border: 1px solid #cacaca;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 12px;
    margin-bottom: 14px;
  }

  .item-body {
    display: flex;
    flex-direction: row;
  }

  .price-view {
    display: flex;
    flex-direction: row;
    width: 350px;
    justify-content: flex-end;
  }

  .count-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4px;
  }

  .count-btn {
    width: 24px;
    height: 21px;
    background-color: #e7e7e7;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .count-item {
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    margin: 0 15px;
  }

  .desc-view {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 2px;
  }

  .desc-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: black;
  }

  .address-view {
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 5px;
  }

  .payment-method-view {
    border: 1px solid #d1d1d1;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 15px;
  }

  .delivery-notes-view {
    font-size: 11px;
    line-height: 23px;
    font-weight: bold;
    margin-top: 15px;
  }

  .address-header {
    display: flex;
    align-self: stretch;
    height: 30px;
    background-color: #efefef;
    align-items: center;
    padding: 0px 11px;
    font-size: 11px;
    color: black;
    font-weight: bold;
  }

  .address-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 12px;
    margin-top: 5px;
  }

  .address-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: black;
  }

  .edit {
    position: absolute;
    right: 25px;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 2px;
    width: 50px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 8px;
    line-height: 23px;
    color: #727272;
    background-color: white;
    padding: 0;
  }

  .address-add {
    margin-top: 5px;
    background-color: #e9e9e9;
    border-radius: 2px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 9px;
    line-height: 23px;
    color: #727272;
    padding: 0 33px;
  }

  .more {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    width: 47px;
    height: 12px;
    background-color: #f0f0f0;
    border-radius: 2px;
    padding-left: 14px;
    padding-right: 14px;
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .more-circle {
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background-color: #c4c4c4;
  }

  .deliver-description {
    font-size: 11px;
    line-height: 13px;
    font-weight: 500;
    color: #c1c1c1;
    margin-top: 9px;
  }

  .confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 9px;
    padding-bottom: 9px;
    background: #c30000;
    border-radius: 2px;
    margin-top: 10px;
    font-weight: bold;
    font-size: 10px;
    color: white;
    line-height: 30px;
    letter-spacing: 4.72px;
    width: 100%;
  }

  .address-margin {
    margin-top: 55px;
  }

  .deliver-address {
    font-size: 14px;
    line-height: 11px;
  }

  @media (max-width: 1024px) {
    .deliver-address {
      font-size: 15px;
      line-height: 24px;
    }

    .address-margin {
      margin-top: 20px;
    }

    .title {
      font-size: 30px;
    }

    .deliver-text {
      font-size: 17px;
      line-height: 20px;
    }

    .item-cart {
      padding: 15px 0
    }
  }
</style>
