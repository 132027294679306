<template lang="html">
	<div class="modal fade" id="addressesModal" tabindex="-1" role="dialog" aria-labelledby="addressesModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-mobile" role="document" style="height: 653px; margin:auto">
			<div class="modal-content" style="border-radius: 20px !important; height: 653px; margin: 0 10px;">
				<div class="popup-login">
					<button class="btn close-btn" data-dismiss="modal" style="top: 27px; right: 20px;">X</button>
					<div class="login-title" style="margin-top: 32px; margin-bottom: 10px;">DELIVERY ADDRESSES</div>
					<div class="search">
						<div class="address-item">
							<img src="@/assets/images/icon_search.png" style="width: 17px;"/>
							<input class="search-input" placeholder="Search Address" v-model="keyword"/>
						</div>
					</div>
					<div style="max-height: 500px; position: relative; overflow-y: scroll">
						<a class="address-content" v-for="(address, index) in addresses" :key="index" @click="setAddressIndex(address.id)">
							<div style="width: 20px;">
								<img src="@/assets/images/icon_check.png" style="width: 17px" v-if="id === address.id">
							</div>
							<div style="margin-left: 10px; width: 70%">
								<div class="address-text" :style="id === address.id ? 'opacity: 1': 'opacity: 0.4'">
									{{address.name}}
								</div>
								<div class="address-text font-weight-light" :style="id === address.id ? 'opacity: 1': 'opacity: 0.4'">
									{{`${address.address} ${address.address2 ? `Unit # ${address.address2},` : ''}
									${address.city}, ${address.state} ${address.postalCode}`}}
								</div>
								<div class="address-text font-weight-light" :style="id === address.id ? 'opacity: 1': 'opacity: 0.4'">
									{{getPhoneNumber(get(address, 'phones[0].number', ''))}}
								</div>
							</div>
							<div class="right">
								<button class="btn edit" v-if="id === address.id" @click="onEditAddress(address.id)" data-toggle="modal"
												data-target="#deliverModal">EDIT
								</button>
								<button class="btn edit" style="color: red; margin-top: 1px;" v-if="id === address.id"
												@click="onDeleteAddress(address.id)">DELETE
								</button>
							</div>
						
						</a>
					</div>
					
					<button class="btn btn-login d-flex justify-content-center align-items-center" style="position: absolute; bottom: 0; width: 92%;" type="button" @click="onPressContinue">
						<div class="loader" v-if="state.isLoading"></div>
						<div v-else>CONTINUE</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  import {json} from 'overmind';
  import {get} from 'lodash';

  export default {
    props: ['selectedIndex', 'updateSelectAddress', 'editAddress'],
    setup(props, {root}) {
      const data = reactive({
        id: null,
        keyword: null,
        addresses: json(root.state.currentUser?.sites),
        selectedId: null,
        editId: null,
        get
      });
      const onPressContinue = () => {
        data.selectedId = data.id;
        root.actions.startLoading();
        // $('#addressesModal').modal('hide');
      };

      const setAddressIndex = (id) => {
        data.id = id;
      };

      const onEditAddress = (id) => {
        // data.editId = id;
        props.editAddress(id)
      };

      const getPhoneNumber = (number) => {
        if (!number) {
          return '';
        } else {
          return `(${number.substring(0,3)}) ${number.substring(3, 6)}-${number.substring(6, 10)}`
        }
      };

      const onDeleteAddress = async () => {
        const loader = root.$loading.show();
        try {
          const user = await root.actions.user.deleteUserAddress({siteId: data.id, userId: root.state.currentUser.id});
          data.addresses = data.keyword ? json(user.sites.filter(i =>
            i.name?.toLowerCase()?.includes(data.keyword.toLowerCase()) ||
            i.address?.toLowerCase()?.includes(data.keyword.toLowerCase()) ||
            i.address2?.toLowerCase()?.includes(data.keyword.toLowerCase()) ||
            i.city?.toLowerCase()?.includes(data.keyword.toLowerCase()) ||
            i.state?.toLowerCase()?.includes(data.keyword.toLowerCase()) ||
            i.postalCode?.toLowerCase()?.includes(data.keyword.toLowerCase())))
            : json(user.sites);
        } catch (e) {
          console.log(e);
        } finally {
          loader.hide();
        }
      };
      return {
        onPressContinue,
        setAddressIndex,
        onEditAddress,
        onDeleteAddress,
        getPhoneNumber,
        ...toRefs(data)
      }
    },
    mounted() {
      this.id = this.selectedIndex;
    },
    watch: {
      keyword: function (key) {
        this.addresses = json(this.state.currentUser.sites.filter(i =>
          i.name?.toLowerCase()?.includes(key.toLowerCase()) ||
          i.address?.toLowerCase()?.includes(key.toLowerCase()) ||
          i.address2?.toLowerCase()?.includes(key.toLowerCase()) ||
          i.city?.toLowerCase()?.includes(key.toLowerCase()) ||
          i.state?.toLowerCase()?.includes(key.toLowerCase()) ||
          i.postalCode?.toLowerCase()?.includes(key.toLowerCase())));
      },
      selectedId: function (val) {
        this.updateSelectAddress(val)
      },
      editId: function (val) {
        this.editAddress(val);
      },
      selectedIndex: function (val) {
        console.log(val);
        this.id = val;
      }
    },
    beforeUpdate() {
      this.addresses = json(this.state.currentUser?.sites);
    }
  }
</script>

<style lang="css" scoped>
	input:focus {
		outline-width: 0 !important;
	}
	
	.address-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 10px 12px;
		margin-top: 5px;
	}
	
	.right {
		position: absolute;
		right: 15px;
	}
	
	.edit {
		border: 1px solid #e0e0e0;
		box-sizing: border-box;
		border-radius: 2px;
		width: 50px;
		height: 21px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		font-size: 8px;
		line-height: 23px;
		color: #727272;
		background-color: white;
		padding: 0;
	}
	
	
	.address-text {
		font-weight: 600;
		font-size: 11px;
		color: black;
	}
	
	.address-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}
	
	.search {
		background-color: #fbfbfb;
		display: flex;
		flex-direction: row;
		padding-left: 10px;
		padding-right: 10px;
		margin: 5px 10px;
		height: 50px;
		border-radius: 5px;
		align-items: center;
	}
	
	.search-input {
		border: 0;
		background-color: #fbfbfb;
		width: 100%;
		margin-left: 10px;
	}
</style>
